html {
  scroll-behavior: smooth;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f3f4f6;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

header {
  background-color: #101214;
  color: white;
  padding: 30px 0; /* Increased padding for better spacing */
  text-align: center;
}

.first-screen-wrapper {
  height: 100vh;
  overflow: hidden; /* Ensure that content doesn't spill outside */
}

.more-info-section {
  overflow: hidden; /* Ensure that content doesn't spill outside */
  line-height: 1.5;
  padding: 2rem 0;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px; /* Increased padding for better spacing */
}

.content-wrapper {
  display: flex; /* to lay out its children (logo and signup content) side by side */
  align-items: center; /* center align vertically */
  gap: 50px; /* space between the logo and the signup content */
  width: 100%;
  max-width: 600px; /* set a maximum width */
  margin: 0 auto; /* center the content-wrapper */

  flex-wrap: wrap; /* Allows elements to wrap on smaller screens */
}

.logo-placeholder {
  flex: 1; /* take half the space of the content-wrapper */
  display: flex;
  justify-content: center; /* center the logo horizontally */
  align-items: center; /* center the logo vertically */
  max-width: 800px; /* Restrict logo size */
}

.logo-placeholder img {
  max-width: 100%; /* allows the image to take up to the full width of its parent */
  max-height: 350px;
}

.signup-content {
  flex: 1; /* take half the space of the content-wrapper */
  display: flex;
  flex-direction: column;
  align-items: center; /* center the content vertically */
  gap: 20px;
  width: 100%;
  min-width: 100px; /* Give the signup section a bit more room */
  margin-right: 30px;
}

.signup-section {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  max-width: 800px;
  width: 70%;
  max-width: 600px; /* Allow the signup section to be larger */
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 20px;
}

button {
  background: linear-gradient(to right, #3a8dff, #6872e6);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
}



li {
  text-align: center;
  list-style-type: disc; /* This is the default bullet point, but being explicit */
}

button:hover {
  background: linear-gradient(to right, #2a6dbd, #555bbf);
}

.error {
  color: red;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.first-screen-wrapper {
  position: relative;
  display: flex;
  height: calc(
    100vh - 60px - 60px 
  ); /* Adjusted according to the header and footer padding */
  width: 100%;
  justify-content: space-between;
  padding: 40px 0; /* Add padding for spacing */
}

.scroll-arrow:hover {
  transform: translateY(5px);
}

.scroll-indicator {
  position: absolute;
  bottom: 5%; /* push it up a bit from the very bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.hint-text {
  text-align: center;
  margin-bottom: 20px; /* This provides some space between the hint text and the arrow */
  color: #666; /* Change the color as per your design preference */
  font-style: italic;
}
.scroll-arrow {
  position: absolute;
  bottom: 1%;
  transform: translateX(-5%);
  cursor: pointer;
  font-size: 2em;
  transition: transform 0.3s;
  color: #3a8dff;
  z-index: 1;
}

.scroll-arrow:hover {
  transform: translateY(5px);
}

.about-bulletpoints {
  list-style-position: inherit;
  margin-right: 100px;
  margin-left: 100px;
}

.more-info-section {
  margin-top: 100px;
  margin-bottom: 120px;
  margin-right: 60px;
  padding: 10;
  background: #f3f4f6;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  max-width: 800px;
  width: 100%;
}

.more-info-section-below{
  margin-bottom: 60px;
  padding: 10;
  background: #f3f4f6;
  max-width: 400px;
  width: 100%;
}


footer {
  background-color: #212529;
  color: #f8f9fa;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  font-family: "Lato", sans-serif;
}

.footer-columns {
  display: flex;

  justify-content: space-between;
  gap: 1rem;
}

.footer-column {
  flex: 1;
  text-align: center; /* This will center the content */
}

.footer-column h4 {
  margin-bottom: 1rem;
  color: white;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}
.footer-column ul,
.footer-column .social-icons {
  display: inline-block; /* This will make these block elements act like inline, so they get affected by the parent's text-align center property */
}

.footer-column li {
  margin-bottom: 0.5rem;
}

footer a {
  color: #f8f9fa;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  color: inherit;
  text-decoration: none;
}

.social-icons svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  transition: fill 0.3s;
}

.social-icons a:hover svg {
  fill: #fff;
}

.subscription-success {
  background-color: #e8f5e9; /* light green background */
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.subscription-success h3 {
  color: #2e7d32; /* green text */
  margin: 0;
  font-size: 1.5em;
}

.subscription-success p {
  margin-top: 10px;
}

.signup-invitation {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.signup-invitation a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s;
}

.signup-invitation a:hover {
  color: #0056b3;
}



.about-content {
  flex-direction: column;
  margin-right: 2px;
  margin-left: 2px;
  /* align-items: center; */
  /* gap: 2rem; */
}

/* Make the footer responsive */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
  }

  .footer-column {
    text-align: center;
  }

  .about-bulletpoints {
    list-style-position: inherit;
    margin-right: 0px;
    margin-left: 0px;
  }


  /* .footer-bottom {
    flex-direction: column;
  } */
}

@media (max-width: 480px) {
  .nav-links {
    flex-wrap: nowrap;
  }
}

@media (max-width: 360px) {
  .nav-links {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .logo-placeholder img {
    max-width: 100%; /* allows the image to take up to the full width of its parent */
    max-height: 250px;
  }




  
  .first-screen-wrapper {
    flex-direction: column; 
    height: auto;
    padding: 20px 0; /* Adjusted padding for mobile view */
  }

  .logo-placeholder,
  .signup-content {
    width: 100%; /* Make them full-width on mobile screens */
    max-width: 100%;
  }

  .scroll-arrow {
    position: static;
    margin-top: 20px;
    transform: none;
  }
  .content-wrapper {
    flex-direction: column; /* stack the logo and signup content vertically on smaller screens */
    gap: 20px;
    max-width: 90%;
    margin: 0 auto;
  }

  .signup-section {
    padding: 20px;
    max-width: none;
  }
  .content-wrapper,
  .more-info-section {
    max-width: 90%;
    margin: 0 auto;
  }

  .more-info-section {
    margin-top: 50px;
  }

  .main-content {
    padding: 40px 0; /* Increased padding for better spacing in mobile */
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3a8dff;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-top: 10px;
    align-self: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .success-message {
    color: green;
    margin-top: 15px;
    font-size: 0.9em;
  }

  @media (max-width: 768px) {
    .content-wrapper {
      gap: 20px;
      flex-direction: column;
    }

    .logo-placeholder,
    .signup-content {
      max-width: 100%;
      width: 100%;
    }
  }
}
